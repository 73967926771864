<template>
  <div>
    <div
      v-if="dataLoad"
      class=" text-center my-2"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>

    <b-card
      v-else
      title="Created By :"
      class="mb-3"
    >
      <div class=" px-5">
        <b-row>
          <b-col
            cols="12"
            class="w-100 d-flex justify-content-around"
          >
            <p style="font-size: x-large;">
              <strong>Name:</strong> <span>{{ tasksData.created_by.name }}</span>
            </p>
            <p style="font-size: x-large;">
              <strong>Email:</strong> <span>{{ tasksData.created_by.email }}</span>
            </p>
          </b-col></b-row>
      </div>
      <!-- <p><strong>ID:</strong> {{ tasksData.id }}</p> -->
      <div class="mt-1 show-data-tasks">
        <p v-if="tasksData.description">
          <strong>Description:</strong> {{ tasksData.description }}
        </p>
        <p v-if="tasksData.due_date">
          <strong>Due Date:</strong> {{ tasksData.due_date }}
        </p>
        <p v-if="tasksData.created_by_type">
          <strong>Created By Type:</strong> {{ tasksData.created_by_type }}
        </p>
        <p><strong>Is Late ? </strong> {{ tasksData.is_late ? 'Yes' : 'No' }}</p>
        <p v-if="tasksData.issue">
          <strong>Issue : </strong> {{ tasksData.issue }}
        </p>
        <p v-if="tasksData.priority">
          <strong>Priority : </strong> {{ tasksData.priority }}
        </p>
        <p v-if="tasksData.request_id">
          <strong>Request Id : </strong> {{ tasksData.request_id }}
        </p>
        <p v-if="tasksData.required_action">
          <strong>Required Action : </strong> {{ tasksData.required_action }}
        </p>
        <p v-if="tasksData.status">
          <strong>Status : </strong>
          <span
            :style="`cursor: pointer;  color: ${statusColor(tasksData.status)};`"
            class="hover-pointer-empty"
            @click="viewStaus(tasksData.id)"
          >
            {{ tasksData.status }}
          </span>
        </p>
        <p v-if="tasksData.updated_at">
          <strong>Updated At : </strong> {{ tasksData.updated_at }}
        </p>
      </div>

      <!-- Other task details here... -->

      <div class="mt-1">
        <h5>Assigned Users</h5>
        <div class="d-flex flex-wrap">

          <b-card
            v-for="(assigned, index) in tasksData.assigned"
            :key="index"
            class="mb-3 mr-1 card-tasks-assigned"
          >
            <p><strong>Name:</strong> <span>{{ assigned.user.name }}</span></p>
            <p><strong>Email:</strong> <span>{{ assigned.user.email }}</span></p>
            <p><strong>Assigned Date:</strong> <span>{{ assigned.created_at }}</span></p>
            <P><strong>Updated Date:</strong> <span>{{ assigned.updated_at }}</span></p>
          </b-card>

        </div>
      </div>

      <div class="task-comments">
        <b-col
          cols="12"
          style="border-bottom: 1px solid #d8d6de;"
        >
          <h3 class="py-1">
            comments ({{ tasksData.comments.length }})
          </h3>
        </b-col>
        <b-col
          cols="12"
          style="border-bottom: 1px solid #d8d6de;"
        >

          <ul class="list-unstyled mt-2">
            <b-media tag="li">
              <template #aside>
                <!-- <b-img
                  width="64"
                  alt="placeholder"
                  src="../../../assets//images//avatars/avatr-icon.png"
                /> -->
                <div
                  style="background-color: #f0f2f7;"
                  class="px-1 py-1"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="94"
                  />
                </div>
              </template>
              <h5 class="mt-0 mb-1">
                Add Your Comment
              </h5>
              <b-input-group>
                <b-form-textarea v-model="newComment" />
              </b-input-group>
            </b-media>

          </ul>
          <div class="d-flex justify-content-end mb-1">
            <b-button
              v-if="!loadingBtn"
              variant="primary"
              @click="addComment"
            >
              Add commnet
            </b-button>
            <b-button
              v-if="loadingBtn"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner variant="grow" />

            </b-button>

          </div>
        </b-col>
        <b-col
          v-for="(com, index) in tasksData.comments"
          :key="index"
          cols="12"
          class="my-1 text-center d-flex align-items-center justify-content-start"
        >
          <div class="d-flex w-100">
            <div
              style="background-color: #f0f2f7;"
              class="px-1 py-1 mx-2"
            >
              <feather-icon
                icon="UserIcon"
                size="30"
              />
            </div>
            <div class="w-100 ">
              <b-row>
                <b-col
                  col="12"
                  md="6"
                  class="d-flex justify-content-start"
                >
                  <p>
                    <span style="color: #67b3f5;  font-size: x-large;">  {{ com.user.name }} </span>
                    <span style="color: #c7d0d4; font-size: medium;">{{ com.created_at }}</span>
                  </p>
                </b-col>
                <b-col
                  col="12"
                  md="6"
                  class="d-flex justify-content-end"
                >
                  <span style="color: #67b3f5; font-size: medium;">  {{ com.user.email }} </span>
                </b-col>

              </b-row>

              <p class="mb-0  d-flex">
                {{ com.comment }}
              </p>
            </div>

          </div>

        </b-col>
      </div>

    </b-card>

  </div>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      tasksData: [],
      newComment: '',
      loadingBtn: false,
      dataLoad: false,
    }
  },

  mounted() {
    this.showTasks()
  },

  methods: {
    statusColor(data) {
      switch (data) {
        case 'in-progress':
          return 'green'
        case 'pending':
          return '#f5851d'
        case 'completed':
          return 'blue'
        case 'cancelled':
          return 'red'
        default:
          return 'black' // Fallback color
      }
    },
    showTasks() {
      this.dataLoad = true
      axios.get(`https://gulftic-system.fci.group/api/task/${this.$route.params.id}`).then(res => {
        this.tasksData = decryptData(res.data.payload).data
        // eslint-disable-next-line eqeqeq
        if (res.status == 200) {
          this.dataLoad = false
          this.newComment = ''
        }
      }).catch(err => {
        // console.error('Error fetching task data:', err)
        const errorMessage = err.data.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorMessage,
            icon: 'BellIcon',
            variant: 'error',
          },
        })
      })
        .finally(() => {
          this.dataLoad = false
          this.newComment = ''
        })
    },
    addComment() {
      this.loadingBtn = true
      axios.post(`https://gulftic-system.fci.group/api/task/${this.$route.params.id}/comment`, {
        comment: this.newComment,
      }).then(res => {
        if (res.status === 200) {
          this.loadingBtn = false
          this.newComment = ''
          this.showTasks()
        }
      })
        .catch(error => {
          if (error.response.status) {
            const payload = decryptData(error.response.data.payload).message
            const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
            const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loadingBtn = false
          this.newComment = ''
        })
    },

    viewStaus(id) {
      this.$swal({
        title: 'Select status',
        input: 'select',
        inputOptions: {
          'in-progress': 'In Progress',
          completed: 'Completed',
        },
        inputPlaceholder: 'Select Status',
        showCancelButton: true,
        inputValidator: value => new Promise((resolve, reject) => {
          if (value) {
            resolve()
          } else {
            reject(new Error('You need to select a status'))
          }
        }),
      }).then(result => {
        if (result.isConfirmed) {
          const selectedStatus = result.value
          axios.put(`https://gulftic-system.fci.group/api/task/${id}/status`, {
            status: selectedStatus,
          })

            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Status Updated',
                text: `You selected: ${selectedStatus}`,
              }).then(() => {
                // Reload the page if the status update was successful
                window.location.reload()
              })
            }).catch(error => {
              // console.error('Error updating status:', error)
              this.$swal({
                icon: 'error',
                title: `${error.data.message}`,
                text: 'Failed to update status.',
              })
            })
        }
      })
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .card-tasks-assigned{
    border: 1px solid #d8d6de;
    width: fit-content;
    padding: 8px;
    display: flex;
    flex-direction: column;
    p{
        text-wrap: nowrap;
    }
  }
  .show-data-tasks{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
  }
  .show-data-tasks > * {
    flex: 1 1 50%; /* Each child element will take up 50% of the row */
    box-sizing: border-box; /* Optional: ensures padding/borders don't affect width */
}
.task-comments{
    margin: auto ;
    width: 70vw;
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 5px ;
    font-size: large;
    border: 1px solid #d8d6de;

}
  </style>
